<template>
        <div class="popup-item card-info">
            <button class="close-btn" @click="$emit('close')"></button>
            <div class="card-info__icon">
                <a :href="item.link">
                    <img :src="item.image" alt="" class="card-info__icon-item">
                </a>
            </div>
            <h4 class="card-info__title">
                {{item.title}}
            </h4>
            <p class="card-info__text" v-html="item.text">
            </p>
            <div v-if="item.self" class="card-info__video">
                <iframe v-if="item.lucky === 'group'" src="https://www.youtube.com/embed/6VijcaWldwM?controls=0" frameborder="0"></iframe>
                <iframe v-else-if="item.lucky === 'online'" src="https://www.youtube.com/embed/92Rc1g77PUY" frameborder="0"></iframe>
                <iframe v-else-if="item.lucky === 'feed'" src="https://www.youtube.com/embed/QIAhlxuexfU?controls=0" frameborder="0"></iframe>
                <iframe v-else-if="item.lucky === 'ads'" src="https://www.youtube.com/embed/zq67TcEipHA?controls=0" frameborder="0"></iframe>
            </div>
            <div class="card-info__code" v-if="item.code">
                <h4 class="card-info__code-title">Промокод</h4>
                <input type="text" :value="item.code" ref="copyInput" disabled class="card-info__code-input">
                <i class="card-info__code-copy" @click="copyCode"></i>
            </div>
        </div>
</template>

<script>


export default {
    props: {
        item: Object
    },
    methods:  {
        copyCode() {
            const {copyInput} = this.$refs
            copyInput.disabled = false
            copyInput.select();
            copyInput.setSelectionRange(0, 99999); 
            document.execCommand("copy");
            copyInput.blur();
            const sel = window.getSelection();
            sel.removeAllRanges();
            copyInput.disabled = true
            this.$root.$emit('message', {text: "Успешно скопировано", type: "success"})
        },
        createRef() {
            this.cardRef = document.querySelector('.ref')
        }
    },
    mounted() {
        this.createRef()
        this.cardRef ? this.cardRef.addEventListener('click', ()=>{
            this.$emit("changeCard", Number(this.cardRef.dataset.id))
        }) : void 0
    },
    beforeDestroy() {
        this.cardRef ? this.cardRef.removeEventListener('click', ()=>{
            this.$emit("changeCard", Number(this.cardRef.dataset.id))
        }) : void 0
    }
}
</script>

<style lang="scss">

    .card-info {
        display: flex;
        flex-direction: column;
        padding-top: 372px;
        padding-bottom: 30px;

        @media screen and (max-width: 840px)  {
            padding-top: 260px;
        }  

        &__video {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;

            iframe {
                width: 600px;
                height: 300px;

                @media screen and (max-width: 399px)  {
                    width: 100%;
                    max-height: 180px;
                } 

                @media (min-width: 400px) and (max-width: 840px)  {
                    width: 350px;
                    height: 200px;
                } 
            }

        }

        .close-btn {
            position: absolute;
            z-index: 70;
            top: 15px;
            right: 15px;
            width: 30px;
            height: 30px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjE3NTYgMTEuOTgxOUwxOS44MDc2IDUuMzY3NjFDMTkuOTM4NyA1LjIxNDk3IDIwLjAwNzIgNS4wMTg2NCAxOS45OTk0IDQuODE3ODRDMTkuOTkxNiA0LjYxNzA0IDE5LjkwODIgNC40MjY1NSAxOS43NjU3IDQuMjg0NDZDMTkuNjIzMiA0LjE0MjM3IDE5LjQzMjIgNC4wNTkxMiAxOS4yMzA5IDQuMDUxMzdDMTkuMDI5NSA0LjA0MzYxIDE4LjgzMjcgNC4xMTE5MSAxOC42Nzk2IDQuMjQyNjJMMTIuMDQ3NiAxMC44NTY5TDUuNDE1NjMgNC4yMzQ2NEM1LjI2NDk5IDQuMDg0NCA1LjA2MDY4IDQgNC44NDc2MyA0QzQuNjM0NTkgNCA0LjQzMDI4IDQuMDg0NCA0LjI3OTYzIDQuMjM0NjRDNC4xMjg5OSA0LjM4NDg4IDQuMDQ0MzYgNC41ODg2NSA0LjA0NDM2IDQuODAxMTJDNC4wNDQzNiA1LjAxMzYgNC4xMjg5OSA1LjIxNzM3IDQuMjc5NjMgNS4zNjc2MUwxMC45MTk2IDExLjk4MTlMNC4yNzk2MyAxOC41OTYxQzQuMTk1ODkgMTguNjY3NyA0LjEyNzg3IDE4Ljc1NTcgNC4wNzk4NSAxOC44NTQ3QzQuMDMxODQgMTguOTUzNiA0LjAwNDg1IDE5LjA2MTUgNC4wMDA2IDE5LjE3MTNDMy45OTYzNCAxOS4yODEyIDQuMDE0OSAxOS4zOTA4IDQuMDU1MTIgMTkuNDkzMkM0LjA5NTM0IDE5LjU5NTYgNC4xNTYzNSAxOS42ODg2IDQuMjM0MzEgMTkuNzY2M0M0LjMxMjI4IDE5Ljg0NDEgNC40MDU1MiAxOS45MDQ5IDQuNTA4MTggMTkuOTQ1QzQuNjEwODQgMTkuOTg1MSA0LjcyMDcgMjAuMDAzNiA0LjgzMDg4IDE5Ljk5OTRDNC45NDEwNSAxOS45OTUyIDUuMDQ5MTYgMTkuOTY4MiA1LjE0ODQxIDE5LjkyMDRDNS4yNDc2NiAxOS44NzI1IDUuMzM1OTIgMTkuODA0NiA1LjQwNzYzIDE5LjcyMTFMMTIuMDQ3NiAxMy4xMDY5TDE4LjY3OTYgMTkuNzIxMUMxOC44MzI3IDE5Ljg1MTggMTkuMDI5NSAxOS45MjAxIDE5LjIzMDkgMTkuOTEyNEMxOS40MzIyIDE5LjkwNDYgMTkuNjIzMiAxOS44MjE0IDE5Ljc2NTcgMTkuNjc5M0MxOS45MDgyIDE5LjUzNzIgMTkuOTkxNiAxOS4zNDY3IDE5Ljk5OTQgMTkuMTQ1OUMyMC4wMDcyIDE4Ljk0NTEgMTkuOTM4NyAxOC43NDg4IDE5LjgwNzYgMTguNTk2MUwxMy4xNzU2IDExLjk4MTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
            background-color: transparent;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border: none;
            opacity: .7;

            &:hover, &:focus {
                outline: none;
                border: none;
            }

            &:hover {
                opacity: 1;
            }
        }

        &__icon {
            position: absolute;
            top: -1px;
            right: 0;
            left: 0;
            height: 372px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 840px)  {
                height: 240px;
            }

            a {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;

                img {
                    display: block;
                    object-fit: scale-down;
                    width: 100%;
                    height: 100%;

                    @media screen and (max-width: 840px)  {
                        height: 240px;
                        object-fit: cover;
                    }
                }
            }            
        }

        &__title {
            width: 100%;
            margin: 0;
            margin-bottom: 20px;
            font-size: 30px;
            line-height: 32px;
            font-weight: 600;
            text-align: left;
        }

        &__text {
            margin: 0;
            margin-bottom: 20px;
            width: 100%;
            font-size: 18px;
            line-height: 130%;
            white-space: pre-line;

            a {
                color: #AF5B4C;
                text-decoration: none;
                cursor: pointer;

                &:hover, &:visited {
                    color: #AF5B4C;
                }
            }

            .ref {
                color: #AF5B4C;
                text-decoration: none;
                cursor: pointer;
            }
        }
        
        &__code {
            // width: 400px;
            width: 100%;
            height: 60px;
            margin: 0 auto;
            margin-top: 80px;
            border: 1px solid #AF5B4C;
            background: #FBE8E5;
            border-radius: 10px;
            position: relative;
            display: flex;
            justify-content: center;

            @media screen and (max-width: 840px)  {
                width: 100%;
                max-width: 400px;
                height: 40px;
            }

            &-title {
                position: absolute;
                top: -40px;
                left: auto;
                right: auto;
                margin: 0;
                font-size: 20px;
                line-height: 20px;
            }

            input {
                background: transparent;
                width: calc(100% - 60px);
                margin-right: auto;
                height: 100%;
                border: none;
                padding: 10px 20px;
                font-size: 30px;
                line-height: 30px;
                color: #000;
                font-family: "GothamPro-Bold";

                @media screen and (max-width: 840px)  {
                    padding: 10px;
                    font-size: 20px;
                    width: calc(100% - 30px);
                }
            }

            i {
                position: absolute;
                top: 10px;
                bottom: 10px;
                right: 20px;
                height: 34px;
                width: 34px;
                background-image: url('../../assets/copy.svg');
                background-size: 24px 24px;
                background-repeat: no-repeat;
                background-position: center;
                z-index: 100;
                cursor: pointer;

                @media screen and (max-width: 840px)  {
                    top: 0px;
                    right: 5px;
                }
            }
        }        
    }
</style>